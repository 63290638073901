import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoginModule } from 'login-lib-influxiq';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './common/login/login.component';
import { ShareModule } from './modules/share/share.module';
import { CookieService } from 'ngx-cookie-service';
import { ResolveService } from './services/resolve.service';
import { ApiService } from './services/api.service';
import { ForgotPasswordComponent } from './common/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './common/change-password/change-password.component';


@NgModule({
  declarations: [
    AppComponent,
    // HeaderComponent,
    // FooterComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ShareModule,
    HttpClientModule,
    LoginModule
  ],
  providers: [CookieService,ApiService,ResolveService],
  bootstrap: [AppComponent],
  entryComponents:[],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule { }
