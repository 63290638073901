import { Component, OnInit, Compiler } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {
  public username:any='';
  public loader:boolean = false;
  constructor(public cookies: CookieService, public compiler: Compiler,public router: Router) {
    let allData = this.cookies.getAll();
    console.log("alldata++++",allData);
    this.username=JSON.parse(allData.firstname)+' ' +JSON.parse( allData.lastname);
      console.log("this.username",this.username);
      
    
   }

  ngOnInit() {
  }
  click(val){
    this.loader = true;
    this.router.navigate([val]).then(()=>{
      this.loader = false;
    });
  }
  logout() {
    this.cookies.deleteAll(); // cookie service delete
    this.cookies.deleteAll("/");
    this.compiler.clearCache();
    localStorage.clear(); // local storage clear
    setTimeout(() => {
      this.compiler.clearCache(); // cache clear

      this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
        this.router.navigate(["/"]);
      }); // after loguot navigate to login part
    }, 300);
  }
  myaccount() {
    this.loader = true;
    this.router.navigate(["/users/my-account/" + JSON.parse(this.cookies.get('userid'))]).then(()=>{
      this.loader = false;
    }); // get user account details
  }
}
