import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from 'src/app/common/footer/footer.component';
import { HeaderComponent } from 'src/app/common/header/header.component';
import { ListingModule } from 'listing-angular7';
import { NO_ERRORS_SCHEMA } from '@angular/core';
import { DemoMaterialModule } from '../material-module.module';



@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
  ],
  imports: [
    CommonModule,
    ListingModule,
    DemoMaterialModule
  ],
  exports:[ListingModule,HeaderComponent,
    FooterComponent],

  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class ShareModule { }
