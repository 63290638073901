import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { takeUntil, map } from 'rxjs/operators';
import { Subscription, ReplaySubject } from 'rxjs';

import { ApiService } from '../../services/api.service';
// import { MetaService } from '@ngx-meta/core';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public fromTitle: any = 'Welcome to MM Group';
  public loginflag: boolean = false;
  public fullUrl: any = environment.api_url;
  public forgetRouteingUrl: any = {
    path: '',
    buttonName: 'Forgot Password',
    customLink: '/forgot-password',
    customURl: '',
  };
  public signUpRouteingUrl: any = {
    path: '',
    // 'buttonName':'sign-up',
    customLink: '',
  };
  public routerStatus: any;
  public endpoint: any = 'api2/login';
  public buttonName: any = 'Login';
  public defaultLoginUrl = '/login';
  public ipinfoid = '9797c42b93078a'; // this is info api key
  constructor(public cookie: CookieService, public route: ActivatedRoute, public router: Router, public apiservice: ApiService) { 
    if (this.cookie.check('type') && this.cookie.check('jwtToken')) {
      // const type = JSON.parse(this.cookie.get('type'));
      // this.router.navigate([type + '/dashboard']);
      this.router.navigateByUrl('/dashboard/admin');
      return;
    } else {
      // if (!this.cookie.check('type') &&JSON.parse(this.cookie.get('type')) == 'technological-consultant') {
      // console.log('ppppppppppppppppppppppppp');
      // // localStorage.setItem('dataSource', this.dataSource.length);

      // }

      this.loginflag = true;
      this.routerStatus = {
        data: [
          {
            type: 'admin',
            routerNav: 'dashboard/admin',
            cookies: {
              userid: '_id',
              useremail: 'email',
              firstname: 'firstname',
              lastname: 'lastname',
              jwttoken: 'token',
              status: 'status',
              type: 'type',
              lastlogincity: 'lastlogincity',
              lastloginregion: 'lastloginregion',
              lastlogindate: 'lastlogin_datetime',
              login_time: 'login_time',
              timeZone: "login_data.timeZone",
              secret: "secret"
            }
          },
          {
            type: 'manager',
            routerNav: 'dashboard/manager',
            cookies: {
              userid: '_id',
              useremail: 'email',
              firstname: 'firstname',
              lastname: 'lastname',
              jwttoken: 'token',
              status: 'status',
              type: 'type',
              lastlogincity: 'lastlogincity',
              lastloginregion: 'lastloginregion',
              lastlogindate: 'lastlogin_datetime',
              login_time: 'login_time',
              timeZone: "login_data.timeZone",
              secret: "secret"
            }
          },
          {
            type: 'employee',
            routerNav: 'dashboard/employee',
            cookies: {
              userid: '_id',
              useremail: 'email',
              firstname: 'firstname',
              lastname: 'lastname',
              jwttoken: 'token',
              status: 'status',
              type: 'type',
              lastlogincity: 'lastlogincity',
              lastloginregion: 'lastloginregion',
              lastlogindate: 'lastlogin_datetime',
              login_time: 'login_time',
              timeZone: "login_data.timeZone",
              secret: "secret"
            }
          },
        ]
      };
    }
  }

  ngOnInit() {
  }

}
