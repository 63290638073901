import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ChangePasswordComponent } from './common/change-password/change-password.component';
import { ForgotPasswordComponent } from './common/forgot-password/forgot-password.component';
import { LoginComponent } from './common/login/login.component';


const routes: Routes = [
  {
    path: "users",
   
    loadChildren: () =>
      import("./components/user-management/user-management.module").then(
        (m) => m.UserManagementModule
      ),
  },
  {
    path: "dashboard",
   
    loadChildren: () =>
      import("./components/users-dashboard/users-dashboard.module").then(
        (m) => m.UsersDashboardModule
      ),
  },
  { path: "login", component: LoginComponent },
  { path: "forgot-password", component: ForgotPasswordComponent },
  { path: "change-password", component: ChangePasswordComponent },
  { path: "**", pathMatch: "full", redirectTo: "login" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
