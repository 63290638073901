import {
  ElementRef,
  EventEmitter,
  Injectable,
  Input,
  ViewChild,
} from '@angular/core';
import { switchMap, map, takeWhile, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, Subscription, throwError } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public api_url = environment.api_url;
  public api_url2 = environment.pythonapi_url;
  public jwttoken='';
  public domain_url = environment.domain_url;
  constructor(private http: HttpClient,
    private cookieservice: CookieService,
    public router: Router,
    public activatedroute: ActivatedRoute,
    private snackbar: MatSnackBar) { 

    }

  getState() {
    const result = this.http.get('assets/data/states.json').pipe(
      catchError((error) => {
        this.openSnackBar();
        return throwError(error);
      }),
      map((response) => response)
    );
    return result;
  }
  customRequest(data,endpoint,url){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // Authorization: this.cookieService.get('jwtToken')
      }),
    };
    const result = this.http
      .post(url + endpoint, JSON.stringify(data), httpOptions)
      .pipe(
        catchError((error) => {
          this.openSnackBar();
          return throwError(error);
        }),
        map((response) => response)
      );
    return result;
  }
  getDatalistWithToken(requestdata: any, endpoint: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    const result = this.http
      .post(this.api_url + endpoint, JSON.stringify(requestdata), httpOptions)
      .pipe(map((res) => res));
    return result;
  }
  openSnackBar() {
    this.snackbar.open('Something Went Wrong ,Try Again!!', 'ok', {
      duration: 6000,
    });
  }
}
