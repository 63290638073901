import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  public loginRouteingUrl: any = {
    path: '',
    buttonName: 'Login',
    customLink: '/login',
    customURl: '/'
  };
  public signUpRouteingUrl: any = {
    path: '',
    // 'buttonName':'Sign-Up',
    customLink: '/sign-up',
    customURl: ''
  };
  public buttonName: any = 'Forgot Password';
  // public signUpRouteingUrl: any = 'sign-up';
  public formTitle: any = 'Forgot Password';
  public serverUrl: any = this.apiservice.api_url;
  public addEndpoint: any = {
    endpoint: 'api2/forgetpassword'
  };
  public domainUrl: any = this.apiservice.domain_url + 'reset-password';
  constructor(public apiservice: ApiService) { }

  ngOnInit() {
  }

}
