import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  formfieldrefresh: any = true;
  formfieldrefreshdata: any = null;
  formfieldrefreshpass: any = true;
  formfieldrefreshdatapass: any = null;
  emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  passwordregex: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
  textregex: RegExp = /^[a-zA-Z]*$/;
  temtdata: any = '';
  // formdata
  updatetable: any = false;
  datasource: any;
  public formdata: any;
  public formdatachngpass: any;
  public stateVal: any = [];
  public status: any = [
    { val: 1, name: 'Active' },
    { val: 0, name: 'Inactive' },
  ];
  public userData: any = [];
  public userDatapass: any = [];
  public resolveval: any;
  public resolvevalpass: any;
  public flag: any;
  public url: any;
  public userid: any;

  constructor(public apiservice: ApiService, public activatedroute: ActivatedRoute, public cookies: CookieService, public router: Router,public snackBar: MatSnackBar) { 
    this.userid = JSON.parse(this.cookies.get('userid'));
   }
  // change password
  ngOnInit() {
    const url = '/users/list';
    this.formdata = {
      successmessage: 'Updated Successfully',
      redirectpath: url,
      submitactive: true,
      submittext: 'Update',
      // resettext: 'Reset',
      canceltext: 'Cancel',
      apiUrl: this.apiservice.api_url,
      // endpoint: 'api/changepassword', //
      jwttoken: this.apiservice.jwttoken,
      cancelroute: url,

      fields: [
        {
          label: 'Current Password',
          name: 'oldpassword',
          type: 'password',
          value: '',

          validations: [
            {
              rule: 'required',
              message:
                'Your provided Password must contain at-least one Uppercase letter, one Lower case letter, and one Number.',
            },
            {
              rule: 'pattern',
              value: '',
              message: 'Must contain a Capital Letter and a Number',
            },
          ],
        },
        {
          label: 'New Password',
          name: 'password',
          type: 'password',
          value: '',

          validations: [
            {
              rule: 'required',
              message:
                'Your provided Password must contain at-least one Uppercase letter, one Lower case letter, and one Number.',
            },
            {
              rule: 'pattern',
              value: this.passwordregex,
              message: 'Must contain a Capital Letter and a Number',
            },
          ],
        },

        {
          label: 'Confirm Password',
          name: 'confirmpassword',
          type: 'password',
          value: '',
          validations: [
            {
              rule: 'required',
              message:
                'Your provided Password must contain at-least one Uppercase letter, one Lower case letter, and one Number.',
            },
            {
              rule: 'match',
              message: 'Confirm Password field Needs to  match Password',
            },
            {
              rule: 'pattern',
              value: this.passwordregex,
              message: 'Must contain a Capital Letter and a Number',
            },
          ],
        },
        {
          label: 'id',
          name: 'id',
          type: 'hidden',
          value: this.userid,
        },
      ],
    }
  }
  listenFormFieldChange(val:any){
    console.log(val);
    if(val.field == "fromsubmit"){
      if(val.fromval.oldpassword != ''){
        if(val.fromval.oldpassword === val.fromval.password){
          this.snacbar_msg('Current password and new password must be different');
        }else{
          let send_data = {
            data:val.fromval,
            secret:this.cookies.get('secret'),
            token:this.cookies.get('jwtToken')
          }
          this.apiservice.getDatalistWithToken(send_data,'api2/changepassword').subscribe((data:any)=>{
            if(data.status == "success"){
              this.snacbar_msg(data.msg);
              this.router.navigateByUrl('/users/list');
            }else{
              this.snacbar_msg(data.msg);
            }
          })
        }
      }
    }
  }
  snacbar_msg(val:string){
    this.snackBar.open(val, 'Close', {
      duration: 500000000
    });
  }
}
