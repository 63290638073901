import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router, RouterEvent, RouterStateSnapshot } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class ResolveService {

  constructor(public apiservice: ApiService,
    public cookie: CookieService,
    public activatedroute: ActivatedRoute,
    public router: Router,
  ) {
    if(!this.cookie.check('jwtToken') || !this.cookie.check('secret') || !this.cookie.check('userid') || !this.cookie.check('useremail')){
      this.router.navigateByUrl('/login');
    }
    this.router.events
      .pipe(filter((event: RouterEvent) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        console.log(event);

      });
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    let requestData = route.data.requestcondition;
    console.log(route.params.id, requestData);
    if (route.params.id && route.params.id != null && typeof route.params.id != 'undefined' && route.params.id != '') {
      requestData.condition['_id'] = route.params.id;
    }
    return new Promise((resolve) => {
      console.log(route);
      this.apiservice
        .customRequest(requestData, route.data.endpoint, route.data.api_url)
        .subscribe((apiobject) => {
          console.log(apiobject);
          if (apiobject['status'] == "success") {
            return resolve(apiobject);
          } else {
            return true;
          }

        })

    })

  }
}
